<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="部门">
                <el-cascader :options="departmentData" :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }" :clearable="true" style="width: 100%" filterable @change="handleOption" size="small"></el-cascader>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="searchForm.type" style="width: 140px" @change="Search" size="small">
                  <el-option label="全部" value="-1"> </el-option>
                  <el-option label="机构" value="0"> </el-option>
                  <el-option label="平台" value="1"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="医生姓名/医生账号" @keyup.enter.native="Search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="doctorName" label="医生姓名"></el-table-column>
          <el-table-column prop="doctorPhone" label="医生账号" align="center"> </el-table-column>
          <el-table-column prop="departmentName" label="所属部门" align="center"></el-table-column>
          <el-table-column prop="mzPatientCount" label="看诊人数" align="center">
            <template slot-scope="scope">
              <el-link type="primary" @click="prviewBtn(scope.row)">{{ scope.row.mzPatientCount }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="patientNum" label="开方数量" align="center"></el-table-column>
          <el-table-column prop="patientMoney" label="处方费(元)" align="center"> </el-table-column>
          <el-table-column prop="tcmDecoctionMoney" label="代煎费(元)" align="center"> </el-table-column>
          <el-table-column prop="appendMoney" label="附加费用(元)" align="center"> </el-table-column>
          <el-table-column prop="expressMoney" label="快递费(元)" align="center"> </el-table-column>
          <el-table-column prop="payMoney" label="总计(元)" align="center">
            <!-- <template slot-scope="scope">
              {{ scope.row.payMoney.toFixed(2) }}
            </template> -->
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    查看    -->
    <el-dialog :visible.sync="infoDialog" width="55%">
      <el-table :data="infoData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="name" label="患者姓名" />
        <el-table-column prop="age" label="年龄" align="center" />
        <el-table-column prop="phone" label="联系方式" align="center" />
        <el-table-column prop="mzPatientCount" label="看诊次数" align="center" />
        <el-table-column prop="mzPatientMoney" label="看诊费用(元)" align="center" />
        <el-table-column prop="mzPatientTime" label="看诊时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.mzPatientTime | timefilters }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="changePage2" :page-size="pageSize2" :current-page="pageIndex2" :total="dataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import { Department } from '@/components/HospitalDomain/Department.js'
import { Patient } from '@/components/HospitalDomain/Patient/Patient.js'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      patientDomain: patient,
      Department: department,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      searchForm: {
        keyWord: '',
        datepicker: '',
        departmentId: 0,
        type: '-1',
      },
      infoData: [],
      infoDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
    }
  },
  mounted() {
    this.getDepartment()
    this.getList()
  },
  methods: {
    getDepartment() {
      let organizationId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.Department.GetDepartment(
        organizationId,
        function (data) {
          _this.departmentData = data.data
        },
        function (err) {
          _this.departmentData = []
        }
      )
    },
    getDetail() {
      var _this = this
      _this.infoData = []
      _this.OutpatientDomain.MZPersonDetail(
        0,
        _this.id,
        _this.pageIndex2,
        async function (data) {
          _this.infoDialog = true
          _this.infoData = data.data.results
          _this.pageIndex2 = data.data.pageIndex
          _this.pageSize2 = data.data.pageSize
          _this.dataTotal2 = data.data.dataTotal
          // let res = await _this.getOrganizationPatientList()
          // if (res) {
          //   infoData.forEach((item) => {
          //     let patients = res.filter((i) => i.id == item.id)
          //     if (patients.length > 0) {
          //       let map = {
          //         ...item,
          //         name: patients[0].name,
          //         age: patients[0].age,
          //         phone: patients[0].phone,
          //       }
          //       _this.infoData.push(map)
          //     }
          //   })
          //   _this.infoDialog = true
          // }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getOrganizationPatientList() {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.patientDomain.GetOrganizationPatientAll(
          function (data) {
            resolve(data.data)
          },
          function (error) {
            console.log(error)
            resolve(false)
          }
        )
      })
    },
    changePage2(pageIndex) {
      this.pageIndex2 = pageIndex
      this.getDetail()
    },
    prviewBtn(item) {
      this.id = item.doctorId
      this.pageIndex2=1
      this.getDetail()
    },
    handleOption(e) {
      this.searchForm.departmentId = e[e.length - 1]
      this.pageIndex = 1
      this.getList()
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.OutpatientDomain.MZDoctorPerformanceInfo(
        0,
        item.keyWord,
        item.departmentId ? item.departmentId : 0,
        item.startTime,
        item.endTime,
        this.pageIndex,
        parseInt(item.type),
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
